import React, { useState, useEffect } from 'react';
import { RiCloseCircleLine } from "react-icons/ri";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios'; // Para hacer la petición
import html2pdf from 'html2pdf.js';
import moment from 'moment'; // Para manejar fechas
import { FaWhatsapp,FaCopy  } from "react-icons/fa"; // Asegúrate de importar el ícono

const NavButton = ({ title, customFunc, icon, color, size = 'xl', padding = 'p-3' }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className={`relative text-${size} rounded-full ${padding} hover:bg-light-gray`}
    >
      {icon}
    </button>
  </TooltipComponent>
);

const simulateFileToBase64 = async (fileName) => {
  try {
    // La ruta asume que las imágenes están en la carpeta 'public/images'
    const response = await fetch(`/images/${fileName}`);
    const blob = await response.blob();
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Devuelve solo el contenido base64
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error(`Error al convertir el archivo ${fileName} a Base64:`, error);
    return null;
  }
};

const DetalleCotizacion = ({ cotizacionId, onClose,onAprobar  }) => {
  const { currentColor } = useStateContext();
  const [cotizacion, setCotizacion] = useState(null); // Estado para almacenar los detalles de la cotización
  const [cliente, setCliente] = useState(null); // Estado para almacenar los detalles del cliente
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado para manejar errores
  const [fechaCotizacion, setFechaCotizacion] = useState('');
  const [totalM2, setTotalM2] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  const getImageFileName = (itemName) => {
    const mappings = {
      optivision: "OptivisionNeutra.png",
      optivisión: "OptivisionNeutra.png",
      blackpearl: "PremiumBlackPearl.png",
      "nova 70": "PremiumNova70.png",
      purelite: "PremiumPureLite.jpg",
      plata: "SilverPlataEspejo.png",
    };
  
    // Busca una coincidencia en las claves del objeto `mappings`
    const matchedKey = Object.keys(mappings).find((key) =>
      itemName.toLowerCase().includes(key)
    );
  
    // Devuelve el nombre del archivo o `null` si no hay coincidencia
    return matchedKey ? mappings[matchedKey] : null;
  };
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!cotizacionId) {
      return; // No intentes cargar si el ID es null
    }
    let isMounted = true; // Para controlar si el componente sigue montado
    // Limpiar estado de error y cliente al abrir el modal
    setError(null);
    setCliente(null);
  
    const fetchCotizacion = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cotizaciones/${cotizacionId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        if (isMounted) {
          setCotizacion(response.data);
          // Formatear la fecha y guardarla
          const formattedDate = new Date(response.data.fecha_cotizacion).toLocaleDateString('es-CL', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          });
          setFechaCotizacion(formattedDate);
    
          if (response.data.solicitante) {
            const clienteResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clientes/clientes/${response.data.solicitante}`, {
              headers: { Authorization: `Bearer ${token}` }
            });
            setCliente(clienteResponse.data); // Asignar el objeto cliente directamente
          }
                  // Calcula el total de metros cuadrados sumando el campo m2 de cada detalle
        const totalMetros = response.data.detalles.reduce((total, item) => {
          return total + (parseFloat(item.m2) || 0); // Asegúrate de que m2 sea un número válido
        }, 0);
        setTotalM2(totalMetros); // Guarda el total de m² en el estado
        setLoading(false);
        }
        console.error("error",error)
      } catch (error) {
        console.error('Error al cargar los detalles de la cotización o cliente:', error);
        if (isMounted) {
          setError('No se pudo cargar la cotización o el cliente.');
          setLoading(false);
        }
      }
    };
  
    fetchCotizacion();
  
    return () => {
      isMounted = false;
    };
  }, [cotizacionId]);

  if (loading) {
    return <div>Cargando los detalles de la cotización...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const generatePDF = async () => {
    try {
      const response = await fetch('/PDFCotizacion.html');
      let templateHTML = await response.text();    
      // Agrupar los detalles
      const groupedDetails = cotizacion.detalles.reduce((acc, item) => {
        const key = `${item.item}-${item.alto}-${item.ancho}-${item.precio}`;
        const precioPorM2 = parseFloat(item.precio) || 0;
        const m2 = parseFloat(item.m2) || 0;
      
        if (!acc[key]) {
          acc[key] = {
            ...item,
            cantidad: 1,
            total: precioPorM2 * m2, // Calcula el total inicial basado en el precio y m2
            m2: m2, // Inicializa los m²
          };
        } else {
          acc[key].cantidad += 1;
          acc[key].m2 += m2; // Suma los m²
          acc[key].total = precioPorM2 * acc[key].m2; // Recalcula el total acumulado
        }
      
        return acc;
      }, {});
      const groupedArray = Object.values(groupedDetails);  
      // Calcular montos
      const totalLaminas = cotizacion.detalles.reduce((total, item) => {
        const precioPorM2 = parseFloat(item.precio) || 0;
        const metrosCuadrados = parseFloat(item.m2) || 0;
        const subtotal = precioPorM2 * metrosCuadrados;    
        return total + subtotal;
      }, 0);
      const montoDificultad = parseFloat(cotizacion.monto_dificultad) || 0;
      const montoRetiroLaminas = parseFloat(cotizacion.monto_retiro_laminas) || 0;
      const montoDistancia = parseFloat(cotizacion.monto_distancia) || 0;
      const montoNeto1 = totalLaminas + montoDificultad + montoRetiroLaminas + montoDistancia;
      const descuento = cotizacion.descuento > 0 ? montoNeto1 * (cotizacion.descuento / 100) : 0;
      const montoNeto = Math.round(montoNeto1 - descuento);
      const iva = Math.round(montoNeto * 0.19);

      templateHTML = templateHTML 
        .replace('{{nombrePago}}', process.env.REACT_APP_NOMBRE_PAGO)
        .replace('{{rutPago}}', process.env.REACT_APP_RUT_PAGO)
        .replace('{{bancoPago}}', process.env.REACT_APP_BANCO_PAGO)
        .replace('{{tipoCuentaPago}}', process.env.REACT_APP_TIPO_CUENTA_PAGO)
        .replace('{{numeroCuentaPago}}', process.env.REACT_APP_NUMERO_CUENTA_PAGO)
        .replace('{{mailCuentaPago}}', process.env.REACT_APP_MAIL_CUENTA_PAGO)
        .replace('{{montoPorM2}}', totalLaminas.toLocaleString('es-CL'))
        .replace('{{dificultad}}', cotizacion.monto_dificultad.toLocaleString('es-CL'))
        .replace('{{retiroLaminas}}', cotizacion.monto_retiro_laminas.toLocaleString('es-CL'))
        .replace('{{distancia}}', cotizacion.monto_distancia.toLocaleString('es-CL'))
        .replace('{{montoNeto}}', montoNeto.toLocaleString('es-CL'))
        .replace('{{iva}}', iva.toLocaleString('es-CL'))
        .replace('{{descuento}}', descuento.toLocaleString('es-CL'))
        .replace('{{totalFinal}}', cotizacion.total_cotizacion.toLocaleString('es-CL'))
        .replace('{{cotizacionId}}', cotizacionId)
        .replace('{{fecha}}', fechaCotizacion)
        .replace('{{tipoInstalacion}}', cotizacion.tipo_instalacion)
        .replace('{{clienteNombre}}', cliente.nombre)
        .replace('{{clienteApellido}}', cliente.apellido)
        .replace('{{clienteWhatsApp}}', cliente.whatsapp)
        .replace('{{clienteEmail}}', cliente.email)
        .replace('{{direccionInstalacion}}', cotizacion.direccion_instalacion)
        .replace('{{clienteComuna}}', cliente.comuna);
        

      // Generar las filas agrupadas
      let detalleItems = '';
      groupedArray.forEach((item, index) => {
        detalleItems += `
          <tr>
            <td>${index + 1}</td>
            <td>${item.item}</td>
            <td>${item.precio.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</td>
            <td>${item.cantidad}</td>
            <td>${item.alto || 'N/A'}</td>
            <td>${item.ancho || 'N/A'}</td>
            <td>${(item.m2).toFixed(2)}</td>
            <td>${item.total.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</td>
          </tr>
        `;
      });
      // Calcula el número de la siguiente fila
      let nextRowNumber = cotizacion.detalles.length + 1;
        // Agregar filas adicionales de Dificultad, Retiro Láminas y Distancia
        if (cotizacion.monto_dificultad) {
          detalleItems += `
            <tr>
              <td>${nextRowNumber++}</td>
              <td colspan="6"><strong>Dificultad</strong></td>
              <td>$${parseFloat(cotizacion.monto_dificultad).toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</td>
            </tr>
          `;
        }
        if (cotizacion.monto_retiro_laminas) {
          detalleItems += `
            <tr>
              <td>${nextRowNumber++}</td>
              <td colspan="6"><strong>Retiro Láminas</strong></td>
              <td>$${parseFloat(cotizacion.monto_retiro_laminas).toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</td>
            </tr>
          `;
        }
    
        if (cotizacion.monto_distancia) {
          detalleItems += `
            <tr>
              <td>${nextRowNumber++}</td>
              <td colspan="6"><strong>Distancia</strong></td>
              <td>$${parseFloat(cotizacion.monto_distancia).toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</td>
            </tr>
          `;
        }
        if (cotizacion.descuento > 0) {
          detalleItems += `
            <tr>
              <td>${nextRowNumber++}</td>
              <td colSpan="6"><strong>Descuento: ${parseFloat(cotizacion.descuento).toLocaleString()}%</strong></td>
              <td>-${(descuento).toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</td>
            </tr>
          `;
        }
      templateHTML = templateHTML.replace('{{detalleItems}}', detalleItems);

      const element = document.createElement('div');
      element.innerHTML = templateHTML;

      const opt = {
        filename: `cotizacion_${cotizacionId}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 3 },
        jsPDF: { unit: 'mm', format: [320, 210], orientation: 'portrait' },
      };

      const pdfBase64 = await html2pdf().from(element).set(opt).outputPdf('datauristring');
      return pdfBase64.split(',')[1]; 
    } catch (error) {
      console.error('Error al generar el PDF:', error);
      alert('Ocurrió un error al generar el PDF. Por favor, inténtalo de nuevo.');
    }
  };

  const handleDownloadPDF = async () => {
    const pdfBase64 = await generatePDF();
    if (pdfBase64) {
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${pdfBase64}`;
      link.download = `cotizacion_${cotizacionId}.pdf`;
      link.click();
    }
  };

  const handleSendPDFByEmail = async () => {
    try {
      const pdfBase64 = await generatePDF();
      if (pdfBase64) {
        const token = localStorage.getItem('access_token');
        const destinatario = cliente.email;
        const asunto = `FilmSolar - Cotización #${cotizacionId}`;
        const mensaje = `
        <p>Estimado/a <strong>${cliente.nombre} ${cliente.apellido}</strong>,</p>
        <p>Adjunto encontrará el PDF de la <strong>cotización #${cotizacionId}</strong>.</p>
        <p>Detalles de la cotización:</p>
        <ul>
          <li><strong>Fecha de cotización: </strong>${fechaCotizacion}</li>
          <li><strong>Total:</strong> ${cotizacion.total_cotizacion.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</li>
          <li><strong>Tipo de instalación:</strong> ${cotizacion.tipo_instalacion}</li>
        </ul>
        <p>Si tiene alguna consulta, no dude en ponerse en contacto con nosotros.</p>
        <p>Saludos cordiales,</p>
        <p><strong>El equipo de FilmSolar</strong></p>
        <p>Correo: <a href="mailto:${process.env.REACT_APP_MAIL_CUENTA_PAGO}">${process.env.REACT_APP_MAIL_CUENTA_PAGO}</a></p>
      `;
      // Procesar las imágenes basadas en los nombres de los ítems
      const archivos = await Promise.all(
      cotizacion.detalles.map(async (item) => {
        const fileName = getImageFileName(item.item);
        if (!fileName) return null;

        const base64Content = await simulateFileToBase64(fileName);

        if (!base64Content) return null;

        return {
          contenido: base64Content,
          nombre: fileName,
          tipo: fileName.endsWith(".png") ? "image/png" : "image/jpeg",
        };
      })
    ).then((results) => results.filter(Boolean));


        console.error("Los archivos son:", archivos)
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/correo/enviar-correo/`,
          {
            destinatario,
            asunto,
            mensaje,
            archivos: [
              ...archivos,
              { contenido: pdfBase64, nombre: `Cotizacion_${cotizacionId}.pdf`, tipo: "application/pdf" },
            ],
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        alert('El PDF se ha enviado correctamente por correo.');
      }
    } catch (error) {
      console.error('Error al enviar el PDF por correo:', error);
      alert('Ocurrió un error al enviar el PDF por correo. Por favor, inténtalo de nuevo.');
    }
  };

  const handleChangeEstado = async (nuevoEstado) => {
    console.error("validamos estado 1",error)
    try {
        const token = localStorage.getItem('access_token');
        
        // Primero, cambia el estado de la cotización
        await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/cotizaciones/${cotizacionId}/cambiar-estado/`, 
        { estado: nuevoEstado }, 
        {
            headers: { Authorization: `Bearer ${token}` }
        });

        // Actualiza el estado localmente para reflejar el cambio
        setCotizacion((prevCotizacion) => ({
            ...prevCotizacion,
            estado: nuevoEstado
        }));
      console.error("validamos estado 2",error)
      // Si el estado es `aprobado`, crea la orden de venta y cierra el modal
      if (nuevoEstado === 'aprobado') {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/ordenes/crear-orden-desde-cotizacion/`,
          { cotizacion_id: cotizacionId },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        console.error("validamos estado 3:",error)
        // Llamar a la función onAprobar para cerrar el modal y refrescar la grilla
        if (onAprobar) {
          onAprobar();
        }
        // Cierra el modal automáticamente
        if (onClose) {
          onClose();
        }
        // Actualiza el estado localmente
        setCotizacion((prev) => ({
          ...prev,
          estado: nuevoEstado,
        }));
      } else {
        // Actualiza el estado localmente para reflejar el cambio
        setCotizacion((prevCotizacion) => ({
          ...prevCotizacion,
          estado: nuevoEstado,
        }));
      }
      console.error("validamos estado 4:",error)
    } catch (error) {
        console.error(`Error al cambiar el estado de la cotización a ${nuevoEstado}:`, error);
        alert('Ocurrió un error al intentar aprobar la cotización y crear la orden de venta.');
    }
};


  const handleAprobarClick = () => {
    const confirmar = window.confirm('¿Estás seguro de que deseas aprobar esta cotización?');
    if (confirmar) {
      handleChangeEstado('aprobado');
    }
    console.error("fin del handle:",error)
  };

  return (
    <>
      <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0 h-full flex">
        
      <div className="responsive-div">
          <div className="flex justify-between items-center mb-6">
            <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Detalle Cotización</p>
            <NavButton
              title="Cerrar"
              customFunc={onClose}
              color={currentColor}
              icon={<RiCloseCircleLine />}
              size="3xl"  // Aumenta el tamaño del ícono
              padding="p-4"  // Aumenta el padding del botón
            />
          </div>

          <div className="cotizacion-header">
            <div className="yellow-bar-left"></div>
            <h1 className="cotizacion-title">COTIZACIÓN {cotizacion.id_cotizacion}</h1>
            <div className="yellow-bar-right"></div>
          </div>

          {/* Información del cliente */}
          {cliente && (
            <div
              className={`cliente-info ${isSmallScreen ? "flex flex-col" : "flex flex-row justify-between"}`}
            >
              <div className={`${isSmallScreen ? "" : "left-info"} mb-4`}>
                <p><strong>Cliente: </strong>{cliente.nombre} {cliente.apellido}</p>
                <p className="direccion-text">
                  <strong>Dirección: </strong>{cotizacion.direccion_instalacion} - {cliente.comuna}
                </p>
              </div>
              <div className={`${isSmallScreen ? "" : "right-info"}`}>
                <p><strong>Teléfono/Wsp: </strong>{cliente.whatsapp}</p>
                <p><strong>Email: </strong><span>{cliente.email}</span></p>
              </div>
            </div>
          )}
          {cliente && (
            <div className="cliente-info">
              <div className="left-info">
                <p><strong>Estado:</strong> {cotizacion.estado}</p>
                <p><strong>Fecha Cotización:</strong> {moment(cotizacion.fecha_cotizacion).format('DD/MM/YYYY')}</p>
                <p><strong>Tipo Servicio:</strong> {cotizacion.tipo_instalacion}</p>
              </div>
              <div className="right-info">
                <p><strong>Retiro Lamina:</strong> {cotizacion.retiro_laminas ? "Sí" : "No"}</p>
                <p><strong>Dificultad:</strong> {cotizacion.dificultad}</p>
                <p><strong>Tipo Propiedad:</strong> {cotizacion.tipo_propiedad}</p>
              </div>
            </div>
          )}




          <table className="items-table table-auto border-collapse w-full">
            <thead>
            <tr className="bg-gray-100">
                <th>Nº</th>
                <th>Item</th>
                <th>Precio (m²)</th>
                <th>Cant.</th>
                {isSmallScreen ? (
                  <th>Medidas</th>
                ) : (
                  <>
                    <th>Alto</th>
                    <th>Ancho</th>
                  </>
                )}
                <th>M²</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {(() => {
                // Agrupa los detalles por columnas clave
                const groupedDetails = cotizacion.detalles.reduce((acc, item) => {
                  const key = `${item.item}-${item.alto}-${item.ancho}-${item.m2}-${item.total}`;
                  if (!acc[key]) {
                    acc[key] = { ...item, cantidad: 1 };
                  } else {
                    acc[key].cantidad += 1;
                  }
                  return acc;
                }, {});
                console.error("groupedDetails",Object.values(groupedDetails))
                // Convierte el objeto agrupado en un array para iterar
                const groupedArray = Object.values(groupedDetails);

                // Renderizar filas agrupadas
                const rows = groupedArray.map((item, index) => {
                  const totalM2 = (parseFloat(item.m2) || 0) * item.cantidad; // Total m² multiplicado por cantidad
                  const totalPrecio = (parseFloat(item.total) || 0); // Total precio multiplicado por cantidad

                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.item}</td>
                      <td>{(item.precio / 1).toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</td>
                      <td>{item.cantidad}</td>
                {isSmallScreen ? (
                  <td>{`${item.alto || 'N/A'} x ${item.ancho || 'N/A'}`}</td>
                ) : (
                  <>
                    <td>{item.alto || 'N/A'}</td>
                    <td>{item.ancho || 'N/A'}</td>
                  </>
                )}
                      <td>{totalM2 ? `${totalM2.toFixed(2)}` : 'N/A'}</td>
                      <td>{totalPrecio ? `$${totalPrecio.toLocaleString()}` : 'N/A'}</td>
                    </tr>
                  );
                });

                // Agregar filas adicionales
                let extraRowIndex = groupedArray.length;
                if (cotizacion.retiro_laminas) {
                  rows.push(
                    <tr key="retiro_laminas">
                      <td>{++extraRowIndex}</td>
                      {isSmallScreen ? (
                        <td colSpan="4">Retiro de láminas</td> // Ajusta el colspan a 2 para pantallas pequeñas
                      ) : (
                        <td colSpan="6">Retiro de láminas</td> // Mantén colspan 6 para pantallas grandes
                      )}
                      <td>{`$${parseFloat(cotizacion.monto_retiro_laminas).toLocaleString()}`}</td>
                    </tr>
                  );
                }

                if (cotizacion.cobro_distancia) {
                  rows.push(
                    <tr key="cobro_distancia">
                      <td>{++extraRowIndex}</td>
                      {isSmallScreen ? (
                        <td colSpan="4">Monto por distancia</td> // Ajusta el colspan a 2 para pantallas pequeñas
                      ) : (
                        <td colSpan="6">Monto por distancia</td> // Mantén colspan 6 para pantallas grandes
                      )}
                      <td>{`${(cotizacion.monto_distancia).toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}`}</td>
                    </tr>
                  );
                }

                if (cotizacion.dificultad !== 'normal') {
                  rows.push(
                    <tr key="dificultad">
                      <td>{++extraRowIndex}</td>
                      {isSmallScreen ? (
                        <td colSpan="4">{`Dificultad: ${cotizacion.dificultad}`}</td> // Ajusta el colspan a 2 para pantallas pequeñas
                      ) : (
                        <td colSpan="6">{`Dificultad: ${cotizacion.dificultad}`}</td> // Mantén colspan 6 para pantallas grandes
                      )}
                      <td>{`${parseFloat(cotizacion.monto_dificultad).toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}`}</td>
                    </tr>
                  );
                }

                if (cotizacion.descuento > 0) {
                  // Calcular monto base (sin IVA ni descuento)
                  const montoBase = cotizacion.total_cotizacion / 1.19;
                  // Calcular monto antes del descuento
                  const montoAntesDescuento = montoBase / (1 - (cotizacion.descuento / 100));
                  // Calcular el descuento
                  const descuento = montoAntesDescuento - montoBase;
                  rows.push(
                    <tr key="descuento">
                      <td>{++extraRowIndex}</td>
                      {isSmallScreen ? (
                      <td colSpan="4">Descuento: {parseFloat(cotizacion.descuento).toLocaleString()}%</td> // Ajusta el colspan a 2 para pantallas pequeñas
                    ) : (
                      <td colSpan="6">Descuento: {parseFloat(cotizacion.descuento).toLocaleString()}%</td> // Mantén colspan 6 para pantallas grandes
                    )}
                      <td>-{descuento.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</td>
                    </tr>
                  );
                }
                // Agregar fila para el IVA
                const ivaTotal = (cotizacion.total_cotizacion - (cotizacion.total_cotizacion / 1.19)); // Calcula el IVA total
                rows.push(
                  <tr key="iva">
                    <td>{++extraRowIndex}</td>
                    {isSmallScreen ? (
                      <td colSpan="4">IVA</td> // Ajusta el colspan a 2 para pantallas pequeñas
                    ) : (
                      <td colSpan="6">IVA</td> // Mantén colspan 6 para pantallas grandes
                    )}
                    <td>{ivaTotal.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</td>
                  </tr>
                );
                return rows;
              })()}
            </tbody>
          </table>

          {/* Monto total debajo de la tabla */}
          <div className="flex justify-end mt-4">
            <p className="font-semibold text-lg">Total: {(cotizacion.total_cotizacion / 1).toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</p>
          </div>

          {/* Monto total de m2 debajo de la tabla */}
          <div className="flex justify-end mt-2">
            <p className="font-semibold text-lg">Total m²: {totalM2.toFixed(2)} m²</p>
          </div>


          <div className="flex flex-col md:flex-row justify-between mt-4 space-y-2 md:space-y-0 md:space-x-2">
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
            <button onClick={handleDownloadPDF} type="button" 
            className="bg-yellow-500 text-white p-2 rounded-lg hover:bg-yellow-600 transition duration-200 w-full md:w-auto"
            >
              Descargar PDF
            </button>
            <button onClick={handleSendPDFByEmail} type="button" className="bg-yellow-500 text-white p-2 rounded-lg hover:bg-red-600 transition duration-200 w-full md:w-auto">
              Enviar por Email

            </button>

              <a
              href={
                cliente
                  ? `https://web.whatsapp.com/send?phone=${cliente.whatsapp.replace(/[^0-9]/g, '')}&text=${encodeURIComponent(
                      `Estimado Sr. ${cliente.nombre} ${cliente.apellido},

              Adjunto encontrará la cotización N°${cotizacionId}, junto con la ficha técnica del film, el cual cumple con todas las características solicitadas.

              Detalles de la cotización:
              - Metros cuadrados (m²): ${totalM2.toFixed(2)}
              - Total: $${cotizacion.total_cotizacion.toLocaleString('es-CL')}
              - Tipo de servicio: ${cotizacion.tipo_instalacion}

              Ante cualquier consulta adicional, quedamos atentos para responder a la brevedad.

              Saludos cordiales,
              Equipo FilmSolar`
                    )}`
                  : '#'
              }
              target="_blank"
              rel="noopener noreferrer"
              className={`inline-flex items-center justify-center bg-green-500 text-white p-2 rounded-lg ${
                cliente ? 'hover:bg-green-600' : 'cursor-not-allowed'
              } transition duration-200 ml-2`}
              onClick={(e) => {
                if (!cliente) {
                  e.preventDefault();
                  alert('Los datos del cliente aún no están disponibles.');
                }
              }}
              >
              <FaWhatsapp className="mr-2" /> Enviar por Wsp
              </a>
            </div>


            
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
              {cotizacion.estado === 'pendiente' && (
                <>
                  <button
                    type="button"
                    className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition duration-200 w-full md:w-auto"
                    onClick={handleAprobarClick}
                  >
                    Aprobar
                  </button>
                  <button
                    type="button"
                    className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition duration-200 w-full md:w-auto"
                    onClick={() => handleChangeEstado('rechazado')}
                  >
                    Rechazar
                  </button>
                </>
              )}
            </div>
          </div>
      </div>
      </div>
    </>
  );
};

export default DetalleCotizacion;
